import React from 'react'
import styled from '@emotion/styled'
import BookSlider from './BookSlider'
import { AppContext } from '../../AppContext'
import BookReadingPage from './BookReadingPage'
import { TextField } from '@mui/material'

const HomeWrapper = styled.div({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    ".header": {
        margin: 20,
        fontSize: 40,
        fontWeight: 700
    }

})

const BooksWrapper = styled.div({
    display: "block",
    width: "700px",
    height: "500px",
    // border: "5px solid orange",
    overflow: "hidden",
})

const HomeBookReading = () => {
    const { context, setContext } = React.useContext(AppContext);
    
    

    const onPickBook = book => {
        setContext({
            ...context,
            AppComponent: BookReadingPage,
            addParams: {
                book
            }
        })
    }

    return (
        <HomeWrapper>
            <div className="header">Hello Aria!</div>
            <div className="header">Pick the book you want to read:</div>

            <BooksWrapper>
                <BookSlider
                    onPickBook={onPickBook} />
            </BooksWrapper>
            <div>
                <TextField label="Action code" value={context.actionCode}
                    onChange={e => {
                        setContext({ ...context, actionCode: e.target.value });
                    }} />
                <TextField label="Change code" value={context.changeCode}
                    onChange={e => {
                        setContext({ ...context, changeCode: e.target.value });
                    }} />

            </div>
        </HomeWrapper>
    )
}

export default HomeBookReading