import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { books } from './Books';
import styled from '@emotion/styled';
import { useKeyboard } from '../../hooks/useKeyboard';

const BookCardWrapper = styled.div({
    boxSizing: "border-box",
    width: '500px',
    height: '500px',
    padding: 20,
    
    "img": {
        border: "5px solid green", 
        width: "100%",
        height: "100%",
        cursor: "pointer"
    }
})

const BookCard = ({book, onClick}) => {
    return (
        <BookCardWrapper>
            
            <img src={`${process.env.PUBLIC_URL}/${book.image}`} onClick={onClick} />
        </BookCardWrapper>
    )
}

const BookSlider = ({onPickBook, onHighlightBook}) => {
  const sliderRef = React.useRef(null);

  const [currentBook, setCurrentBook] = React.useState(books[0]);
  
  useKeyboard(
    () => {onPickBook(currentBook)},
    () => {sliderRef.current.slickNext()}
  );

  const settings = {
    // className: "slider variable-width",
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 500,
    // slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true
  };

  

  return (
    <Slider ref={sliderRef} {...settings} afterChange={(index) => {setCurrentBook(books[index])}}>
        {books.map( book => (
            
            <BookCard book={book} onClick={ () => {
                onPickBook?.(book);
            }}/>
            
        ))}
    </Slider>
  )
}

export default BookSlider