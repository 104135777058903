import HomeBookReading from './apps/BookReading/HomeBookReading';
import React from 'react';
import { AppContext } from './AppContext';
import Home from 'apps/Home/Home';




function App() {
  const [context, setContext] = React.useState({ AppComponent: Home, actionCode: 'Enter', changeCode: 'Space', addParams: {}});
  const {AppComponent, addParams} = context;

  return (
    <AppContext.Provider value={{context, setContext}}>
      <AppComponent {...addParams} />
    </AppContext.Provider>
  );
}

export default App;
