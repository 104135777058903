import React from 'react'
import styled from '@emotion/styled'
import { AppContext } from '../../AppContext'
import HomeBookReading from 'apps/BookReading/HomeBookReading'
import BigButton from 'components/BigButton'
import ABCBookQuiz from 'apps/ABCBookQuiz/ABCBookQuiz'
import TalkDetector from 'apps/TalkDetector/TalkDetector'

const HomeWrapper = styled.div({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    ".header": {
        margin: 20,
        fontSize: 40,
        fontWeight: 700
    }

})



const Home = () => {
    const { context, setContext } = React.useContext(AppContext);
    
    

    const onPickApp = app => {
        setContext({
            ...context,
            AppComponent: app,
            addParams: {}
        })
    }

    return (
        <HomeWrapper>
            <BigButton onClick={e => {onPickApp(ABCBookQuiz)}}>
                ABC Quiz
            </BigButton>
            <BigButton onClick={e => {onPickApp(HomeBookReading)}}>
                Book Reading
            </BigButton>
            <BigButton onClick={_ => onPickApp(TalkDetector)}>
                Talk
            </BigButton>
            
        </HomeWrapper>
    )
}

export default Home