
import React from "react";

export function usePressEvent(onPress, delay) {

    const {current: timeoutMap} = React.useRef(new Map());

    const onPointerDown = React.useCallback( e => {
        let curTimeout = timeoutMap.get(e.pointerId);
        if (curTimeout) {
            clearTimeout(curTimeout);
        }

        curTimeout = setTimeout( () => {
            onPress(e);
        }, delay);

        timeoutMap.set(e.pointerId, curTimeout);

    }, [onPress, delay, timeoutMap]);

    const onPointerCancel = React.useCallback( e => {
        let curTimeout = timeoutMap.get(e.pointerId);
        if (curTimeout) {
            clearTimeout(curTimeout);
        }
    }, [timeoutMap]);
    
    return {
        onPointerDown,
        onPointerCancel,
        onPointerLeave: onPointerCancel,
        onPointerUp: onPointerCancel,
        onPointerOut: onPointerCancel
    }
}