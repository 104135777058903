import React from 'react'
import { AppContext } from '../../AppContext'
import HomeBookReading from './HomeBookReading';
import styled from '@emotion/styled';
import { useKeyboard } from '../../hooks/useKeyboard';
import { usePressEvent } from 'hooks/usePressEvents';

const Wrapper = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "black",
  'video': {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  }
})

const DimmedDiv = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.8)',
  transition: "all 0.5s",
  opacity: 0,
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 50,
  cursor: "pointer",
  touchAction: "none"
})

const VideoWrapper = styled.div({
  width: "100%",
  height: "100%",
  position: "relative"
})

const BookReadingPage = ({book}) => {
  const [nextStopIndex, setNextStopIndex] = React.useState(0);
  const [waitingForResume, setWaitingForResume] = React.useState(false);
  const videoRef = React.useRef(null);

  

  const {context, setContext} = React.useContext(AppContext);

  React.useEffect(() => {
    const timeStamps = [];
    const onKeyUp = e => {
      console.log(e.code);
      if (e.code === 'Backquote') {
        timeStamps.push(videoRef.current.currentTime);
        console.log(timeStamps);
      }
    };
    document.addEventListener('keyup', onKeyUp);

    return () => {document.removeEventListener('keyup', onKeyUp)};
  })

  React.useEffect( () => {
    if (nextStopIndex >= book.timeStamps.length) return;

    const interval = setInterval(() => {
      if (videoRef.current.currentTime >= book.timeStamps[nextStopIndex]) {
        clearInterval(interval);
        setWaitingForResume(true);
        videoRef.current.pause();
      }
    })
    return () => { clearInterval(interval)}
  }, [nextStopIndex])

  const onResume = () => {
    setNextStopIndex(nextStopIndex + 1);
    videoRef.current.play();
    setWaitingForResume(false);
  }

  const onHomeClick = () => {
    setContext( {
      ...context,
      AppComponent: HomeBookReading,
      addParams: {}
  })

  }

  useKeyboard(
    () => {onResume()},
    () => {onHomeClick()}
  )

  const pointerCallbacks = usePressEvent(e => {waitingForResume && onResume();}, 400 );

  return (
    <Wrapper>
      <VideoWrapper>
      <video ref={videoRef} src={`${process.env.PUBLIC_URL}/${book.video}`} autoPlay />
      <DimmedDiv style={{opacity: waitingForResume ? 1 : 0}} {...pointerCallbacks}>
        Turn the page Aria!
      </DimmedDiv>
      </VideoWrapper>
      <button 
        disabled={!waitingForResume} 
        onClick={onResume}>Resume</button>
      <button onClick={onHomeClick}>
        Home
      </button>
    </Wrapper>
  )
}

export default BookReadingPage