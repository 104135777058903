

export const books = [
    {
        name: "Fox in Socks",
        // image: foxInSocksImage,
        image: 'pics/fox-in-socks.jpg',
        video: 'vids/fox-in-socks.mp4',
        timeStamps: [
            4.045579,
            11.006393,
            17.73341,
            31.182824,
            40.412873,
            49.727089, 
            60.443425,
            73.778329,
            79.865859,
            90.54034,
            101.817218,
            108.953809,
            121.153601,
            129.878691,
            139.114202,
            150.241996,
            159.217754,
            173.703021,
            182.054451,
            194.285468,
            207.791837,
            217.358855,
            234.114124,
            243.169602,
            258.962214,
            271.197779,
            282.930818,
            288.305907,
            303.654916,
            309.057839
        ]
    },
    {
        name: "Hop on Pop",
        image: 'pics/hop-on-pop.jpg',
        
    }
]