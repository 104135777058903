import styled from '@emotion/styled';
import { Mic, Settings, Stop } from '@mui/icons-material';
import { Fab, Modal, Paper, Stack, TextField, Typography } from '@mui/material'
import DuckImage from 'pics/duck1.jpg'
import React from 'react'

const rewards = [
    {
        pic: 'duck1.jpg',
        audio: ['duck1.mp3', 'duck2.mp3', 'duck3.mp3']
    },
    {
        pic: 'duck2.jpg',
        audio: ['duck1.mp3', 'duck2.mp3', 'duck3.mp3']
    },
    {
        pic: 'duck3.jpg',
        audio: ['duck1.mp3', 'duck2.mp3', 'duck3.mp3']
    },    {
        pic: 'duck4.jpg',
        audio: ['duck1.mp3', 'duck2.mp3', 'duck3.mp3']
    },    {
        pic: 'duck5.jpg',
        audio: ['duck1.mp3', 'duck2.mp3', 'duck3.mp3']
    },    
    {
        pic: 'duck6.jpg',
        audio: ['duck1.mp3', 'duck2.mp3', 'duck3.mp3']
    },
    {
        pic: 'cat1.jpg',
        audio: ['cat1.mp3', 'cat2.mp3', 'cat3.mp3', 'cat4.mp3']
    },
    {
        pic: 'cat2.jpg',
        audio: ['cat1.mp3', 'cat2.mp3', 'cat3.mp3', 'cat4.mp3']
    },
    {
        pic: 'cat3.jpg',
        audio: ['cat1.mp3', 'cat2.mp3', 'cat3.mp3', 'cat4.mp3']
    },
    {
        pic: 'cat4.jpg',
        audio: ['cat1.mp3', 'cat2.mp3', 'cat3.mp3', 'cat4.mp3']
    },
    {
        pic: 'unicorn1.jpg',
        audio: ['baby1.mp3', 'baby2.mp3']
    },
    {
        pic: 'unicorn2.jpg',
        audio: ['baby1.mp3', 'baby2.mp3']
    },
    {
        pic: 'puppy1.jpg',
        audio: ['dog1.mp3', 'dog2.mp3', 'dog3.mp3', 'dog4.mp3', ]
    },
    {
        pic: 'puppy2.jpg',
        audio: ['dog1.mp3', 'dog2.mp3', 'dog3.mp3', 'dog4.mp3', ]
    },
    {
        pic: 'puppy3.jpg',
        audio: ['dog1.mp3', 'dog2.mp3', 'dog3.mp3', 'dog4.mp3', ]
    },
    {
        pic: 'puppy4.jpg',
        audio: ['dog1.mp3', 'dog2.mp3', 'dog3.mp3', 'dog4.mp3', ]
    },
    {
        pic: 'puppy5.jpg',
        audio: ['dog1.mp3', 'dog2.mp3', 'dog3.mp3', 'dog4.mp3', ]
    },

]

class AudioRecorder {
    constructor() {
        
        this.drawingScope = false;
        this.detecting = false;

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            console.log('getUserMedia supported.');
    
            // const dom_record = document.querySelector('#button-record');
            // const dom_record_span = document.querySelector('#button-record>span');
            // const dom_mp3download = document.querySelector('#a-download-mp3');
            // const dom_jsondownload = document.querySelector('#a-download-json');
            navigator.mediaDevices.getUserMedia(
                // constraints - only audio needed for this app
                {
                    audio: true
                })
    
                // Success callback
                .then(this.onGetUserMediaSuccess)
    
                // Error callback
                .catch(function (err) {
                    console.log('The following getUserMedia error occured: ' + err);
                }
                );
        } else {
            console.log('getUserMedia not supported on your browser!');
        }

        
    }

    /**
     * @type {HTMLCanvasElement}
     */
    get canvas() {
        return this._canvas;
    }

    set canvas(c) {
        this._canvas = c;
        this.canvasContext = c?.getContext('2d');
    }

    drawThresholds = () => {
        if (!this.audioParams) return;
        const canvasCtx = this.canvasContext;

        const WIDTH = this.canvas.width;
        const HEIGHT = this.canvas.height;

        canvasCtx.beginPath();
        canvasCtx.strokeStyle = "rgb(255 0 0)";
        canvasCtx.lineWidth = 2;
        canvasCtx.moveTo(0, (255 - this.audioParams.soundOnThreshold) / 128 * HEIGHT / 2);
        canvasCtx.lineTo(WIDTH, (255 - this.audioParams.soundOnThreshold) / 128 * HEIGHT / 2);
        canvasCtx.stroke();

        canvasCtx.beginPath();
        canvasCtx.strokeStyle = "rgb(0 0 255)";
        canvasCtx.lineWidth = 1;
        canvasCtx.moveTo(0, (255- this.audioParams.soundOffThreshold) / 128 * HEIGHT / 2);
        canvasCtx.lineTo(WIDTH, (255 - this.audioParams.soundOffThreshold) / 128 * HEIGHT / 2);
        canvasCtx.stroke();


        
    }

    startDetecting(callback) {
        callback && (this.callbackAfterSoundDetected = callback);
        this.detecting = true;
        // const d = new Date();
        this.soundOnStartTime = -1;
        this.soundOffStartTime = -1;

        this.detectSoundIntervalId && clearInterval(this.detectSoundIntervalId);
        this.detectSoundIntervalId = setInterval(this.detectSound, 50);
        // this.soundOnLastTime = false;
    }

    stopDetecting() {
        this.detecting = false;
        // const d = new Date();
        this.soundOnStartTime = -1;
        this.soundOffStartTime = -1;
        
        clearInterval(this.detectSoundIntervalId);
        this.detectSoundIntervalId = 0;
    }

    /**
     * 
     * @param {Uint8Array} dataArray 
     */
    detectSound = () => {
        const analyser = this.analyser;
        const dataArray = this.dataArray;
        analyser.getByteTimeDomainData(dataArray);

        const n = dataArray.length;
        const d = new Date();
        
        // Check to see if we need to set the sound to On
        if (this.soundOnStartTime < 0) {
            // If ANY intensity above the threshold, we are ON
            for (let i=0;i < n; i++) {
                if (dataArray[i] >= this.audioParams.soundOnThreshold) {
                    this.soundOnStartTime = d.getTime();
                    break;
                }
            }
        }

        // Check to see if we need to set the sound off time
        // if (this.soundOffStartTime < 0) {
            // ALL intensities need to be below the threshold to be off
            let allOff = true;
            for (let i=0;i<n; i++) {
                if (dataArray[i] >= this.audioParams.soundOffThreshold) {
                    allOff = false;
                    break;
                }
            }
            if (allOff && this.soundOffStartTime < 0) {
                this.soundOffStartTime = d.getTime();
            } else if (!allOff) {
                this.soundOffStartTime = -1;
            }
        // }

        // // If sound is louder than the off threshold, set the off-timer off
        // if (dataArray[n-1] >= this.audioParams.soundOffThreshold) {
        //     this.soundOffStartTime = -1;
        // }


        if (this.soundOnStartTime > 0 && d.getTime() - this.soundOnStartTime > this.audioParams.soundOnTimeLimit) {
            this.callbackAfterSoundDetected();
            this.stopDetecting();
            return;
        }

        if (this.soundOffStartTime > 0 && d.getTime() - this.soundOffStartTime > this.audioParams.soundOffTimeLimit) {
            this.soundOnStartTime = -1;
        }

    }

    /**
     * 
     * @param {HTMLCanvasElement} canvas 
     */
    startDrawingScope(canvas) {
        this.canvas = canvas;
        this.drawingScope = true;
        if (this.draw) {
            this.draw();
        }
    }

    stopDrawingScope() {
        this.drawingScope = false;
    }

    onGetUserMediaSuccess = stream => {
        const audioCtx = new AudioContext();
        const analyser = audioCtx.createAnalyser();
        this.analyser = analyser;

        const source = audioCtx.createMediaStreamSource(stream);
        source.connect(analyser);
        
        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        this.dataArray = dataArray;

        this.draw = () => {
            if (!this.drawingScope) return;

            const canvasCtx = this.canvasContext;
            if (!canvasCtx) return;

            const WIDTH = this.canvas.width;
            const HEIGHT = this.canvas.height;
            
            analyser.getByteTimeDomainData(dataArray);


            canvasCtx.fillStyle = "rgb(200 200 200)";
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
            

            canvasCtx.beginPath();
            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = "rgb(0 0 0)";

            const sliceWidth = WIDTH / bufferLength;
            let x = 0;
            
            for (let i = 0; i < bufferLength; i++) {
                const v = dataArray[i] / 128.0;
                const y = v * (HEIGHT / 2);
              
                if (i === 0) {
                  canvasCtx.moveTo(x, y);
                } else {
                  canvasCtx.lineTo(x, y);
                }
              
                x += sliceWidth;
              }
            canvasCtx.lineTo(WIDTH, HEIGHT / 2);
            canvasCtx.stroke();

            this.drawThresholds();
            
            
            requestAnimationFrame(this.draw);
        }

        // draw();

    }
}

const ScopeContainer = styled('div')({
    ' #checkbox-show-scope:checked + canvas': {
        display: "block",
    },
    ' canvas': {
        display: "none",
        maxWidth: "100%"
    }
})

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

const getRandomReward = () => {
    const reward = rewards[getRandomInt(rewards.length)];
    const pic = reward.pic;

    const audio = new Audio(
        `${process.env.PUBLIC_URL}/audio/${reward.audio[getRandomInt(reward.audio.length)]}`);
        

    return {pic, audio};
}


const RewardContainer = styled('div')({
    border: "2px solid orange",
    borderRadius: "10px",
    overflow: "hidden",
    display: "flex",
    " img": {
        maxWidth: "100%",
        maxHeight: "100%"
    }
})

const TalkDetector = () => {

    const [state, setState] = React.useState('idle');
    const [reward, setReward] = React.useState(getRandomReward());

    // const canvasRef = React.useRef();
    // const [isRecording, setIsRecording] = React.useState(false);
    const [audioParams, setAudioParams] = React.useState({
        soundOnThreshold: 135,
        soundOnTimeLimit: 1000,
        soundOffThreshold: 131,
        soundOffTimeLimit: 500,
        rewardDuration: 7000,
    });

    const {current: data} = React.useRef({
        soundOnStartTime: 0,
        soundOffStartTime: 0,
        audioRecorder: null,
    });

    const {soundOnThreshold, soundOnTimeLimit, soundOffThreshold, soundOffTimeLimit, rewardDuration} = audioParams;

    React.useEffect(() => {
        data.audioRecorder = new AudioRecorder();
    }, [data])

    React.useEffect(() => {
        data.audioRecorder.audioParams = audioParams;
    }, [audioParams, data])

    React.useEffect(() => {
        if (state === 'detecting') {
            data.audioRecorder.startDetecting(_ => setState("show-reward"));

            return () => {data.audioRecorder.stopDetecting();}
        }

        if (state === 'show-reward') {
            reward.audio.play();
            const timeoutId = setTimeout(() => {
                setState('detecting');
                // data.audioRecorder.startDetecting(_ => setState('show-reward'));
            }, rewardDuration);
            return () => {
                clearTimeout(timeoutId);
                setReward(getRandomReward());
            }
        }

        if (state === 'settings') {
            data.audioRecorder.startDrawingScope(document.getElementById('audio-volume-display'));

            return () => data.audioRecorder.stopDrawingScope();
        }
    }, [state, data, reward])


    return (
        <>
        <Stack direction="column" alignItems="center" gap="20px" sx={{
            width: "100vw",
            height: "100vh",
            padding: "5px",
        }}>
            <Typography variant={'h2'} color="orange" sx={{
                textAlign: "center"
            }}>Quack Quack Quack!</Typography>
            <Stack direction="row" gap="10px">
            <Fab 
                color={state==='detecting' || state==='show-reward' ? "error" : "primary"}
                onClick={_ => {
                
                if (state !== 'detecting' && state !== 'show-reward' ) {
                    setState('detecting');
                } else {
                    setState('idle');
                }

            }}>
                {(state === 'detecting' || state === 'show-reward') ? <Stop /> : <Mic /> }
            </Fab>
            <Fab
                onClick={_ => setState('settings')}>
                    <Settings />
            </Fab>
            </Stack>
            {/* <RewardContainer>
                <img src={`${process.env.PUBLIC_URL}/pics/${reward.pic}`} />
                </RewardContainer> */}
            { (state === 'show-reward' && (
                <>
                <RewardContainer>
                <img src={`${process.env.PUBLIC_URL}/pics/${reward.pic}`} />
                </RewardContainer>
                {/* <audio src={`${process.env.PUBLIC_URL}/audio/${reward.audio}`} autoplay /> */}
                </>
            ))}
        </Stack>

        <Modal 
            keepMounted // Keep Mounted so the canvas element is available
            open={state==='settings'}
            onClose={_ => setState('idle')}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: 'center'
            }}
            >
            <Paper sx={{
                width: 600,
                maxWidth: "100%"
            }}>
        <Stack direction="column" sx={{
            margin: "20px",
            width: "300px",
            maxWidth: "100%",
            gap: "20px"
        }}>
            <TextField 
                value={soundOnThreshold} 
                label="Sound On Threshold (128-255)"
                onChange={e => setAudioParams(p => ({
                    ...p, 
                    soundOnThreshold: Number(e.target.value)
                    }))} />
            <TextField 
                value={soundOnTimeLimit} 
                label="Sound On Time Limit"
                onChange={e => setAudioParams(p => ({
                    ...p, 
                    soundOnTimeLimit: Number(e.target.value)
                    }))} />
            <TextField 
                value={soundOffThreshold} 
                label="Sound Off Threshold (128-255)"
                onChange={e => setAudioParams(p => ({
                    ...p, 
                    soundOffThreshold: Number(e.target.value)
                    }))} />
            <TextField 
                value={soundOffTimeLimit} 
                label="Sound Off Time Limit"
                onChange={e => setAudioParams(p => ({
                    ...p, 
                    soundOffTimeLimit: Number(e.target.value)
                    }))} />
            <TextField 
                value={rewardDuration} 
                label="Reward Duration"
                onChange={e => setAudioParams(p => ({
                    ...p, 
                    rewardDuration: Number(e.target.value)
                    }))} />
            
            <ScopeContainer>
            <label>
                Show oscilloscope
                <input type="checkbox" id="checkbox-show-scope" defaultChecked/>
                <canvas width="500" height="500" id="audio-volume-display" />
            </label>
        </ScopeContainer>
                
        </Stack>
        

            </Paper>
        </Modal>
        
        </>
    )
}

export default TalkDetector