import React from 'react'
import { AppContext } from '../../AppContext'
import styled from '@emotion/styled';
import { useKeyboard } from '../../hooks/useKeyboard';
import Home from 'apps/Home/Home';
import { Stack } from '@mui/material';
import BigButton from 'components/BigButton';
import {ReactComponent as ButtonSVG} from 'pics/button_opt.svg';
import { usePressEvent } from 'hooks/usePressEvents';

const book = {
    video: "vids/seuss-abc.mp4",
    timeStamps: [
      10.735072,
      24.879219,
      40.495289,
      50.581293,
      62.46339,
      72.009304,
      84.537016,
      93.365728,
      104.561318,
      116.636744,
      128.025071,
      139.844795,
      151.792924,
      163.09783,
      175.224455,
      196.489286,
      208.460665,
      221.102931,
      232.592595,
      244.446441,
      256.870297,
      267.706494,
      278.876607,
      290.937897,
      301.127248,
      329.055455
  ]
}

const Wrapper = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "black",
  'video': {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  }
})

const DimmedDiv = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.8)',
  transition: "all 0.5s",
  opacity: 0,
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "30px",
  fontSize: 50,
  cursor: "pointer",
  touchAction: "none",
  userSelect: "none",
})

const VideoWrapper = styled.div({
  width: "100%",
  height: "100%",
  position: "relative"
})

const AblenetButtonWrapper = styled.span( ({color}) => ({
  'svg': {
    width: "300px",
    height: "300px",
    marginTop: "-50px",
  },
  'span': {
    fontSize: "180px",
    color: color,
    WebkitTextStrokeColor: "black",
    WebkitTextStrokeWidth: "2px",
  },
  '#top': {
    fill: color
  }
}))

// const AblenetButtonWrapper = styled.span({
//   color: "red"
// })

const delay = 400;

const AblenetButton = ({color, label, onClick, ...props}) => {
  const pointerEvents = usePressEvent(onClick, delay);

  return (
  
  <AblenetButtonWrapper color={color} {...pointerEvents} {...props}>
    <Stack alignItems="center">
      <span>{label}</span>
      <ButtonSVG />
    </Stack>
    
  </AblenetButtonWrapper>
)
  }



function getChar(number) {
  return String.fromCharCode('A'.charCodeAt() + number);
}

function getAltChar(number) {
  let candidate = number;
  while (candidate === number) {
    candidate = Math.floor(Math.random() * 26);
  }
  return getChar(candidate);
}


const ABCBookQuiz = () => {
  const [nextStopIndex, setNextStopIndex] = React.useState(0);
  const [{currentLetter, altLetter, side}, setLetters] = React.useState({});
  const [message, setMessage] = React.useState("");
  const [waitingForResume, setWaitingForResume] = React.useState(false);
  const videoRef = React.useRef(null);

  

  const {context, setContext} = React.useContext(AppContext);

  React.useEffect(() => {
    const timeStamps = [];
    const onKeyUp = e => {
      console.log(e.code);
      if (e.code === 'Backquote') {
        timeStamps.push(videoRef.current.currentTime);
        console.log(timeStamps);
      }
    };
    document.addEventListener('keyup', onKeyUp);

    return () => {document.removeEventListener('keyup', onKeyUp)};
  })

  React.useEffect( () => {
    if (nextStopIndex >= book.timeStamps.length) return;

    const interval = setInterval(() => {
      if (videoRef.current.currentTime >= book.timeStamps[nextStopIndex]) {
        clearInterval(interval);
        setWaitingForResume(true);
        setMessage("Aria Pick " + getChar(nextStopIndex));
        setLetters( {
          currentLetter: getChar(nextStopIndex),
          altLetter: getAltChar(nextStopIndex),
          side: Math.round(Math.random())
        })
        videoRef.current.pause();
      }
    })

    return () => {clearInterval(interval)}
  }, [nextStopIndex])

  const onResume = () => {
    setNextStopIndex(nextStopIndex + 1);
    setMessage("Good job Aria!");
    videoRef.current.play();
    setWaitingForResume(false);
  }

  const onHomeClick = () => {
    setContext( {
      ...context,
      AppComponent: Home,
      addParams: {}
  })

  }

  useKeyboard(
    () => {onLeftClick()},
    () => {onRightClick()}
  )

  const onLeftClick = e => {
    if (!waitingForResume) return;

    if (side) {
      onResume();
    } else {
      setMessage("WRONG!! Try again Aria!")
    }
  }

  const onRightClick = e => {
    if (!waitingForResume) return;
    if (!side) {
      onResume();
    } else {
      setMessage("WRONG!! Try again Aria!")
    }
  }

  return (
    <Wrapper>
      <VideoWrapper>
      <video ref={videoRef} src={`${process.env.PUBLIC_URL}/${book.video}`} autoPlay />
      <DimmedDiv style={{opacity: waitingForResume ? 1 : 0}} >
        <div>
          {message}
        </div>          
        
        <Stack direction="row" width="100%">
            <div style={{width: "7%"}} />
            <AblenetButton color="blue" onClick={onLeftClick} label={side ? currentLetter : altLetter} />
            <div style={{flexGrow: 1}} />
            <AblenetButton color="red" onClick={onRightClick} label={side ? altLetter : currentLetter} />
            <div style={{width: "7%"}} />
            {/* <BigButton onClick={onLeftClick}>
              {side ? currentLetter : altLetter}
            </BigButton>
            <BigButton onClick={onRightClick}>
              {side ? altLetter : currentLetter}
            </BigButton> */}
        </Stack>
      </DimmedDiv>
      </VideoWrapper>
      <button 
        disabled={!waitingForResume} 
        onClick={onResume}>Resume</button>
      <button onClick={onHomeClick}>
        Home
      </button>
    </Wrapper>
  )
}

export default ABCBookQuiz