import styled from '@emotion/styled'
import React from 'react'

const Comp = styled.div({
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "40px",
    border: "3px solid orange",
    borderRadius: "5px"
})

const BigButton = ({onClick, children}) => {
  return (
    <Comp onClick={onClick}>
        {children}
    </Comp>
  )
}

export default BigButton