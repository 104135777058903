import { AppContext } from "../AppContext";
import React from "react";

export function useKeyboard(onAction, onChange) {
    const {context} = React.useContext(AppContext);
    const {actionCode, changeCode} = context;

    React.useEffect(() => {
        const onKeyUp = e => {
            // console.log(e);
            if (e.target instanceof HTMLInputElement) return;
            if (e.code === actionCode) {
                onAction && onAction();
            } else if (e.code === changeCode) {
                onChange && onChange();
            }
        };

        document.addEventListener('keyup', onKeyUp);

        return () => {document.removeEventListener('keyup', onKeyUp)}
 
    }, [actionCode, changeCode, onAction, onChange]);
}